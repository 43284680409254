import _ from "lodash";

/**
 * Сервис трансформации данных
 */
export default {
    /**
     * Адаптирует данные для компонента treeview
     * @param {*} datasorce Исходные данные
     * @returns Возвращает трансормированные данные
     */
    transformData(datasorce) {
        if (!Array.isArray(datasorce) || datasorce.length == 0) return [];

        return datasorce.filter((i) => i.parentId == '00000000-0000-0000-0000-000000000000').map((i) => this.transformItem(datasorce, i));
    },
    /**
     * Адаптирует единичный элемент
     * @param {*} datasorce Исходные данные
     * @param {*} item Исходные элемент
     * @returns Возвращает трансформированный элемент
     */
    transformItem(datasorce, item) {
        let children = datasorce.filter((i) => i.parentId == item.id).map((i) => this.transformItem(datasorce, i));

        return {
            id: item.id,
            name: item.name,
            data: item,
            children: children,
        };
    },
    /**
     * Адаптирует массив в источник данных для v-treeview
     * @param {*} data 
     * @param {*} datasource 
     */
    transformTreeData(rootItem, data, sourceDocumentId)
    {
        if (rootItem)
        {
            if (rootItem.id == 'Links')
            {
                rootItem.children = _(data.filter(i=> i.parent == rootItem.id))
                    .groupBy('values[1]')
                    .map((value, key) => ({ type: key, values: value, text: 'Link', sourceDocumentId }))
                    .orderBy(['type'], ['desc'])
                    .value();                  
            }
            else
            {
                if (['0', '2'].includes(rootItem.text))
                    rootItem.hasExecutions = data.some(i => i.text == '1' && i.parent == rootItem.id && !!i.values[5] && !['0', '1'].includes(i.values[2]));

                rootItem.children = data.filter(i=> i.parent == rootItem.id);
                rootItem.children.forEach(i=> this.transformTreeData(i, data, sourceDocumentId));
            }
        }       
        else
        {
            let result = data.filter(i => i.parent == '#');
            result.forEach(i=> this.transformTreeData(i, data, sourceDocumentId));
            return result;
        }
    }
};
