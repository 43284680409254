<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-icon>fas fa-filter</v-icon>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <!--#region Theme  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.EFTheme.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.EFTheme.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.EFTheme.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region RegNumber  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.RegNumber.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.RegNumber.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.RegNumber.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region NomenclatureId  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.NomenclatureId.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                
                                <v-select
                                    v-model="val.NomenclatureId.value"
                                    :items="nomenclatures"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    multiple
                                    return-object                                    
                                    outlined
                                    dense
                                    hide-details
                                    clearable
                                    append-icon="fas fa-chevron-down"                                    
                                    @click:clear="val.NomenclatureId.value = null"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >

                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    autofocus
                                                    v-model="nomenclatureSearch"
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>

                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region JuridicalDocType  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.JuridicalDocType.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <v-select
                                return-object
                                :items="juridicalDocTypeItems"
                                v-model="val.JuridicalDocType.value"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                :search-input.sync="juridicalDocTypeSearchInput"
                                @change="juridicalDocTypeSearchInput = ''"
                                @click:clear="val.JuridicalDocType.value = null"
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                                <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchJuridicalDocType"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                            </v-select>

                        </v-col>
                    </v-row>
                    <!--#endregion -->
                    
                    <!--#region RegDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateFrom.value"
                                        @input="regDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region RegDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateTo.value"
                                        @input="regDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region Languages  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.Language.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="languagesItems"
                                    v-model="val.Language.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.Language.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region Executers  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.OutgoingExecuters.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="innerExecuters"
                                    v-model="val.OutgoingExecuters.value"
                                    item-text="Value"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    @click:clear="val.Executers.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchExecuters"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region Recipients  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.Recipients.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="correspondentItems"
                                    v-model="val.Recipients.value"
                                    item-text="Value"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    multiple
                                    :search-input.sync="recipientsSearchInput"
                                    @change="recipientsSearchInput = ''"
                                    @click:clear="val.Recipients.value = []"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchRecipients"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region SignerEmployeeIds  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.SignerEmployeeIds.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <v-select
                                return-object
                                :items="signers"
                                v-model="val.SignerEmployeeIds.value"
                                item-text="Value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                multiple
                                @change="signersSearchInput = ''"
                                @click:clear="val.SignerEmployeeIds.value = []"
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-text-field 
                                                :placeholder="$t('Поиск')"
                                                class="mb-2 autocomplete-search"
                                                hide-details
                                                dense
                                                clearable
                                                @input="signersSearch"
                                                autofocus
                                            >
                                            </v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <template v-slot:item="data">
                                    <div class="d-flex flex-column custom-sel-two-level">
                                        <span>{{ data.item.Value }} </span>
                                        <span>{{ data.item.jobTitle }}</span>
                                    </div>
                                </template>

                            </v-select>

                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region SendStatus  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.SendStatus.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="sendStatuses"
                                    v-model="val.SendStatus.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.SendStatus.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                </v-select>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                        v-if="isFormValid"
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';
import { httpAPI } from "@/api/httpAPI";

const getSignersOrEmpty = async (parentComponent) => {
    //получаем полный объект фильтра через родительский компонент и ссылку на текущий список в нем
    let filter = parentComponent?.$refs?.currentComponent?.filter;
    let signersFilterStr = "{}";

    if (typeof filter === 'object') {
        // комментарий ниже нужен ОБЯЗАТЕЛЬНО, иначе не могу исключить из объекта ненужные поля и сформировать правильный фильтр подписантов!
        // eslint-disable-next-line no-unused-vars
        let { sortBy, sortDesc, page, itemsPerPage, groupBy, groupDesc, mustSort, multiSort, SearchStr, extendedFilterData, ...signersFilter } = filter;
        //добавляем тип документа
        signersFilter['DocType'] = "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument";
        signersFilterStr = JSON.stringify(signersFilter);
    }

    let response = await httpAPI({
        url: `api/references/existingsigners?filter=${signersFilterStr}`,
        method: 'GET',
        skipErrorHandler: true
    });

    if (response?.data?.success && response.data.payload)
        return response.data.payload;

    return [];
}

export default {
    name: "CorrespondenceOutgoingExFilterDlg",
    props: {
        value:
        {
            type: Object,
            default: null
        }
    },
    asyncComputed: {
        nomenclatures : {
            async get() {
                if (this.nomenclatureSearch) {
                    let selectedIds = this.val.NomenclatureId.value.length ? this.val.NomenclatureId.value.map(i => i.id) : [];

                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}`, type: parseInt(x[3]) }))
                        .filter(x => x.type === 4 && sys.checkSearch(x.Value, this.nomenclatureSearch) || selectedIds.includes(x.id));
                }
                else
                    return (await this.$store.dispatch('references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Value: `${x[1]} ${x[2]}`, type: parseInt(x[3]) }))
                        .filter(x => x.type === 4);
            },
            default: [],
            watch: ['nomenclatureSearch']
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),
            
            correspondentItems: [],
            innerExecuters: [],

            regDateFromMenu: false,
            regDateToMenu: false,
            outDateFromMenu: false,
            outDateToMenu: false,

            languagesItems: [],

            recipientsSearchInput: "",

            nomenclatureSearch: "",

            juridicalDocTypeItems: [],
            juridicalDocTypeSearchInput: "",

            signers: [],
            signersSearchInput: ""
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('references', { sendStatuses: 'GetSendStatuses'})
    },
    methods: {
        async initialize() {
            this.correspondentItems = (await this.getContractors())
            .map(i =>
            ({
                id: i[0],
                Value: i[1]
            }))
            .sort(function (a, b) {
                return ('' + a.Value).localeCompare(b.Value);
            });

            this.innerExecuters = (await this.getWorkplaces()).InnerExecuters.Executers
            .map(i =>                 
            ({
                id: i[0],
                Value: i[1],
                employeeId: i[2],
                jobTitle: i[3]
            }))
            .sort(function (a, b) {
                return ('' + a.Value).localeCompare(b.Value);
            });

            this.languagesItems = await this.getReference({ id: 1000 });
            this.juridicalDocTypeItems = await this.getReference({ id: 1001 });

            this.signers = this.val.SignerEmployeeIds ? await getSignersOrEmpty(this.$parent) : [];
        },
        ...mapActions('references', ['getContractors', 'getReference', 'getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);

            this.setOverlayVisible({ visible: true, text: 'Загрузка...' });                
            
            this.initialize();

            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async updateSigners() {
            
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);                                
            //this.$notify.success("Фильтр_применен");
            this.resolve();
        },
        handleDate(source) {
            var formatedDate = sys.dateFormat(source, 'DD.MM.YYYY');
            return formatedDate ?? "";
        },
        async searchExecuters(e) {
            if (e) {
                let selectedIds = this.val.Executers.value.length ? this.val.Executers.value.map(i => i.id): [];                    
                this.innerExecuters = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.innerExecuters = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]}));
        },
        async searchRecipients(e) {
            if (e) {
                let selectedIds = this.val.Recipients.value.length ? this.val.Recipients.value.map(i => i.id): [];                    
                this.correspondentItems = (await this.getContractors()).map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.correspondentItems = (await this.getContractors()).map(i => ({id: i[0], Value: i[1]}));
        },
        async searchJuridicalDocType(e) {
            if (e) {
                let selectedId = this.val.JuridicalDocType.value ? this.val.JuridicalDocType.value.id : null;                    
                this.juridicalDocTypeItems = (await this.getReference({ id: 1001 })).filter( i => sys.checkSearch(i.Value, e) || selectedId == i.id);
            }
            else
                this.juridicalDocTypeItems = (await this.getReference({ id: 1001 }));
        },
        async signersSearch(e) {
            if (e) {
                let selectedIds = this.val.SignerEmployeeIds.value.length ? this.val.SignerEmployeeIds.value.map(i => i.id) : [];
                this.signers = await getSignersOrEmpty(this.$parent).filter(i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.signers = await getSignersOrEmpty(this.$parent);
        }
    }
}
</script>