<template>

    <div class="wp-row" v-if="!loading && internalCriterias.length">

        <div class="flex-grids flex-item-middle">

            <div class="filter-title">{{ $t("Условия") }}:</div>

            <div class="flex-item-expand status-panel status-panel-ef d-flex flex-wrap">

                <template v-for="(criteria, i) in internalCriterias">

                        <v-select
                            :key="criteria.name + i"
                            v-if="criteria.type == 'select'"
                            return-object
                            :items="criteria.filteredItems"
                            :value="criteria.isMultipleTarget
                                ? extendedFilter[criteria.name].value[0]
                                : extendedFilter[criteria.name].value"
                            @input="criteria.isMultipleTarget
                                ? extendedFilter[criteria.name].value = [ $event ]
                                : extendedFilter[criteria.name].value = $event"
                            :item-text="item => $refLocale(item, $i18n.locale)"
                            hide-details
                            outlined
                            dense
                            append-icon="fas fa-chevron-down"
                            class="autocomplete-chip ma-1"
                            :label="$t(criteria.label)"
                            :menu-props="{ bottom: true, offsetY: true }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field
                                            :placeholder="$t('Поиск')"
                                            class="mb-2 autocomplete-search"
                                            hide-details
                                            dense
                                            clearable
                                            autofocus
                                            @input="search(criteria, $event)"
                                        >
                                        </v-text-field>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>

                </template>

            </div>

        </div>

    </div>

</template>

<script>
import sys from '@/services/system';

export default {
    name: "CriteriasPanel",
    props: {
        value: {
            type: Object,
            default: () => null
        },
        criterias: {
            type : Array,
            default: () => []
        }
    },
    computed: {
        extendedFilter : {
            get () {                
                return this.value;
            },
            set (val) {
                this.$emit('update:value', val);
            }
        }
    },
    data() {
        return {
            loading: true,
            internalCriterias: JSON.parse(JSON.stringify(this.criterias))
        }
    },
    methods: {
        search(criteria, searchStr) {
            if (searchStr) {
                criteria.filteredItems = criteria.items.map(x => x).filter(i => sys.checkSearch(i.Value, searchStr));
            }
            else {
                criteria.filteredItems = criteria.items.map(x => x);
            }
        }
    },
    async created () {
        this.loading = true;

        for (const criteria of this.criterias) {
            if (criteria.type === 'select') {
                let internal = this.internalCriterias.find(x => x.name == criteria.name);
                internal.items = await criteria.init(this.$store);
                internal.filteredItems = internal.items.map(x => x);
            }
        }
        
        this.loading = false;
    }
}
</script>